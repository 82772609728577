<template>
	<div class="home">
		<van-search v-model="searchaV" @search="onSearch" shape="round" show-action placeholder="请输入搜索内容">
			<template #action>
				<div class="fc fw" @click="onSearch">搜索</div>
			</template>
		</van-search>
		<div v-if="hasData">
			<div class="flex-around" style="background-color:white">
				<h3 :class="{f5:true,title : true,'active-title': activeTitle ==item.id}" @click="changeParams(item)" v-for="(item,index) in majorsTree"
				 :key="index">
					{{item.text}}
				</h3>
			</div>
			<div class="flex-normal">
				<div class="inline div3">
					<ul class="leftUl" ref="typeList">
						<li v-for="(item,index) in secondMajors" :class="{active : activeTypeId == item.id}">
							<div class="ttt" :class="{lightg: item.expand}">
								<p @click="changeParams(item)" :class="{fc: item.id == selectedSecondMajor.id}">{{ item.text }}</p>
								<i :class="item.expand ? 'icon-up':'icon-down'" @click="item.expand = !item.expand" />
							</div>
							<ul v-show="item.expand" :class="{lightg: item.expand}">
								<li v-for="(node,i) in item.nodes" :key="'node'+i" class="third" :class="{fc: node.id == selectedThirdMajor.id}"
								 @click="changeParams(node)">{{ node.text }}</li>
							</ul>
						</li>
					</ul>
				</div>
				<div class="inline div7">
					<van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="pageSelect" class="rightUl"
					 ref="rightUl">
						<div v-for="(item,index) in expertList" :key="index" @click="toExpertInfo(item)" style="position:relative">
							<i class="status-ban" v-if="item.enableStatus =='1'"></i>
							<div class="pad02">
								<i :class="{'icon-male':item.sex=='1','icon-female': item.sex=='2','icon-zhongxing': (item.sex==null||item.sex=='')}"></i>
							</div>
							<div>
								<h3 class="f5 van-multi-ellipsis--l2">{{ item.name }}</h3>
								<p class="gray">手机号：{{item.mobilePhone}}</p>
								<p class="van-ellipsis">{{item.orgName}}</p>
							</div>
						</div>
					</van-list>
				</div>

			</div>
		</div>
		<div class="pad" v-else>
			<van-swipe :autoplay="3000" vertical :show-indicators="false" style="height:1.17rem" :class="{hide: !showTips}">
				<van-swipe-item>
					<p class="demo">
						<van-icon name="cross" size=".27rem" class="closeTips" @click="showTips = false" />
						<i class="icon-tips"></i>
						通过微信电脑版，可在电脑上使用专家记哦~
					</p>
				</van-swipe-item>
				<van-swipe-item>
					<p class="demo">
						<van-icon name="cross" size=".27rem" class="closeTips" @click="showTips = false" />
						<i class="icon-tips"></i>
						<span>免费体验抽取操作，还不快试试~</span>
						<button @click="$router.push('/demo')">即刻体验</button>
					</p>
				</van-swipe-item>
			</van-swipe>
			<div class="f-tips">
				<h3>专家记操作指南</h3>
				<div class="flex tips">
					<img src="/static/img/null/profession.png">
					<div>
						<article class="yellow">第一步：设置评审专业</article>
						<p class="text-tips">
							请在<strong class="fc" @click="$router.push('/mine')">用户中心</strong>的<strong class="fc" @click="$router.push('/profession')">评审专业</strong>，选择评审专业
						</p>
					</div>
				</div>
				<div class="flex tips">
					<img src="/static/img/null/expert.png">
					<div>
						<article class="yellow">第二步：添加评标专家</article>
						<p class="text-tips">
							请在<strong class="fc" @click="$router.push('/mine')">用户中心</strong>的<strong class="fc" @click="$router.push('/expert')">专家管理</strong>，<strong
							 class="fc" @click="$router.push('/expertAdd')">添加专家</strong>信息
						</p>
					</div>
				</div>

				<div class="flex tips">
					<img src="/static/img/null/extract.png">
					<div>
						<article class="yellow">第三步：进行专家抽取</article>
						<p class="text-tips">
							将评审专业和评标专家设置完成后，即可体验
							<strong class="fc" @click="$router.push('/step1')">专家抽取</strong>
						</p>
					</div>
				</div>
				<div class="flex tips">
					<img src="/static/img/null/czy.png">
					<div>
						<article class="yellow">允许多人操作</article>
						<p class="text-tips">
							请在<strong class="fc" @click="$router.push('/mine')">用户中心</strong>的
							<strong class="fc" @click="$router.push('/account')">操作员管理</strong>，设置操作员
						</p>
					</div>
				</div>
			</div>
		</div>
		<expert-footer :activeIndex="activeIndex" />
	</div>
</template>

<script>
	import {
		Search,
		List,
		Icon,
		Swipe,
		SwipeItem
	} from 'vant';
	import expertFooter from '@/components/Footer.vue'
	export default {
		name: "Home",
		data() {
			return {
				searchaV: '',
				activeTitle: '',
				activeTypeId: '',
				expertList: [],
				loading: true,
				finished: false,
				finishedText: '没有更多了',
				pageSize: 10,
				pageIndex: 1,
				activeIndex: '0',
				// 是否有专家数据： 默认 有
				hasData: true,
				showTips: true,
				majorsTree: [],
				secondMajors: [],
				selectedSecondMajor: {},
				selectedThirdMajor: {},
				majorParams: {
					lev: '',
					majorId: ''
				},
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},
		components: {
			[Icon.name]: Icon,
			[List.name]: List,
			[Search.name]: Search,
			[Swipe.name]: Swipe,
			[SwipeItem.name]: SwipeItem,
			expertFooter
		},

		beforeRouteLeave(to, from, next) {
			if (!to.name || to.name === 'nickname' || to.name == 'usercheck')
				next(false)
			else
				next()
		},

		created: function() {			
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			this.getAllExpert()
			this.initMajor()
		},

		methods: {
			/**
			 * @Description: 查询该机构下的所有专家，用户判断是否有数据
			 * @Author: Niklaus
			 * @Date: 2021-05-28 08:21:48
			 */
			getAllExpert() {				
				this.$ajax({
					url: "/gateway/ed/expert/tbEdExpertInfo/queryAllExpertWithOrgId",
					method: "post",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: {
						openid: (this.workweixin?this.openUserid:this.$ls.get("openid")),
						orgId: this.$ls.get("edCurrentOrg").id,
					},
				}).then(res => {
					if (res.data.rows && res.data.rows.length > 0)
						this.hasData = true
					else
						this.hasData = false
				}).catch(error => console.log(error))
			},

			/**
			 * @Description: 查询专业树（三级）
			 * @Author: Niklaus
			 * @Date: 2021-05-27 14:41:50
			 */
			initMajor() {
				let params = new URLSearchParams()
				params.append("orgId", this.$ls.get("edCurrentOrg").id)
				params.append("openid", this.workweixin?this.openUserid:this.$ls.get("openid"))
				this.$ajax({
					url: '/gateway/ed/pc/pcExpertsController/orgMajorsTree',
					headers: {
						'X-Access-Token': this.$ls.get("token")
					},
					params,
				}).then(({
					data
				}) => {
					if (data.success)
						this.majorsTree = data.result || []
					if (this.majorsTree.length > 0) {
						this.majorParams.lev = 1
						this.majorParams.majorId = this.majorsTree[0].id
						this.pageSelect()
						this.activeTitle = this.majorsTree[0].id
						this.secondMajors = this.majorsTree[0].nodes.filter(item => {
							this.$set(item, 'expand', false)
							this.$set(item, 'selected', false)
							return item
						})
					} else {
						this.hasData = false
					}

				}).catch(error => {
					this.$toast.fail('专业数据查询错误');
					console.log(error)
				})
			},

			/**
			 * @Description: 执行搜索
			 * @Author: Niklaus
			 * @Date: 2021-05-28 08:53:46
			 */
			onSearch() {
				this.expertList = []
				this.loading = true
				this.finished = false
				this.pageIndex = 1
				this.pageSelect()
			},

			/**
			 * @Description: 分页查询专家数据
			 * @Author: Niklaus
			 * @Date: 2021-05-28 08:18:49
			 */
			pageSelect() {
				this.$toast({
					type: 'loading',
					message: '加载中，请稍候....',
					forbidClick: true,
					className: 'w4',
					duration: 100
				})

				var params = {}
				params.pageNo = this.pageIndex;
				params.pageSize = this.pageSize;
				params.openid = this.workweixin?this.openUserid:this.$ls.get("openid");
				params.orgId = this.$ls.get("edCurrentOrg").id
				params.lev = this.majorParams.lev
				params.majorId = this.majorParams.majorId
				params.name = this.searchaV
				//查询
				this.$ajax({
					url: "/gateway/ed/pc/pcExpertsController/list",
					method: "get",
					headers: {
						"X-Access-Token": this.$ls.get("token"),
					},
					params: params,
				}).then(res => {
					if (res.data.success) {
						this.loading = false
						this.$toast.clear()
						this.expertList = this.expertList.concat(res.data.result.records);
						if (this.expertList.length >= res.data.result.total)
							this.finished = true
						else
							this.pageIndex++
					} else {
						this.$toast.fail('查询专家数据失败！')
					}
				}).catch(error => {
					this.$toast.fail('查询专家错误')
					console.log(error)
				})
			},

			/**
			 * @Description: 处理不同级别的专业查询参数
			 * @Author: Niklaus
			 * @Date: 2021-05-28 08:19:25
			 * @param {*} item 专业节点
			 */
			changeParams(item) {
				if (item.lev == '1') {
					this.activeTitle = item.id
					this.selectedSecondMajor = {}
					this.selectedThirdMajor = {}
					this.secondMajors = item.nodes.filter(item => {
						this.$set(item, 'expand', false)
						this.$set(item, 'selected', false)
						return item
					})
				} else if (item.lev == '2') {
					this.activeTitle = ''
					this.selectedSecondMajor = item
					this.selectedThirdMajor = {}
				} else if (item.lev == '3') {
					this.activeTitle = ''
					this.selectedSecondMajor = {}
					this.selectedThirdMajor = item
				}
				this.majorParams.lev = item.lev
				this.majorParams.majorId = item.id

				this.expertList = []
				this.loading = true
				this.finished = false
				this.pageIndex = 1
				this.pageSelect()
			},

			//跳转专家详情
			toExpertInfo(expert) {
				this.$router.push({
					path: '/expertInfo',
					query: {
						expertInfo: JSON.stringify(expert)
					}
				})
			},
		}

	}
</script>

<style scoped>
	.div3 {
		width: 3.33rem;
	}

	.div7 {
		width: calc(100% - 3.33rem);
	}

	.home {
		background-color: #F5F5F5;
		min-height: 100vh;
	}

	.leftUl {
		background-color: #fff;
	}

	.leftUl,
	.rightUl {
		height: calc(100vh - 3.95rem);
		overflow: auto;
	}

	.leftUl>li {
		font-weight: bold;
		background-color: #ffffff;
		box-sizing: border-box;
	}

	li.active {
		background-color: #E1FFFB;
		color: #00CCB2;
	}

	.rightUl {
		padding: 0.2rem;
	}

	.rightUl>div {
		background-color: #ffffff;
		border-radius: 0.2rem;
		display: flex;
		display: -webkit-flex;
		padding: 0.2rem 0.2rem 0.3rem 0rem;
		margin-top: 0.15rem
	}

	.rightUl>div>div>i {
		width: 1.5rem;
		height: 1.5rem;
	}

	.rightUl>div>div:last-child {
		width: calc(100% - 1.9rem);
	}

	.rightUl>div>div>h3 {
		font-size: 0.45rem;
		width: calc(100% - 0.5rem);
	}

	.tips img {
		width: 3.2rem;
		height: auto;
	}

	.tips div {
		width: calc(100% - 3.2rem);
		padding-right: .32rem;
	}


	.l-tips {
		background-color: #EBFEFB;
		color: #00CCB2;
		height: 1.2rem;
		font-size: .35rem;
		display: flex;
		align-items: center;
		padding-left: .32rem;
		position: relative;
	}

	.icon-tips {
		width: .64rem;
		height: .64rem;
	}

	.pad {
		padding: .32rem .43rem;
	}

	.f-tips {
		margin-top: .32rem;
		padding-top: .32rem;
		background-color: #fff;
		transition: all .3s ease;
	}

	.f-tips>h3 {
		margin-top: .21rem;
		color: #1e1e1e;
		font-size: .43rem;
		height: .59rem;
		text-align: center;
	}

	.f-tips .tiyan {
		background-color: #E1FFFB;
		height: 1.17rem;
		margin-top: .32rem;
		color: #00A893;
	}

	.yellow {
		color: #FF6F00;
		font-size: .43rem;
		line-height: .59rem;
		font-weight: bold;
	}

	.demo {
		position: relative;
		display: flex;
		align-items: center;
		height: 1.17rem;
		padding-left: .21rem;
	}

	.demo button {
		background-color: #00CCB2;
		border-radius: .5rem;
		height: .67rem;
		padding: .11rem .27rem;
		color: #fff;
		font-size: .32rem;
		line-height: .45rem;
	}

	.text-tips {
		font-size: .37rem;
		line-height: .53rem;
		margin-top: .21rem;
	}

	.text-tips .fc {
		font-size: .37rem;
	}

	.closeTips {
		position: absolute;
		right: 0.11rem;
		top: 0.11rem;
	}

	.hide {
		opacity: 0;
		height: 0;
	}

	.van-swipe-item {
		background-color: #EBFEFB;
		line-height: 1.17rem;
		color: #00A893;
	}

	.ttt {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: .21rem .21rem .21rem 0;
	}

	.ttt>p {
		width: calc(100% - 0.5rem);
		line-height: .56rem;
		padding-left: .31rem;
	}

	.ttt>i {
		width: .43rem;
		height: .43rem;
	}

	.third {
		padding-left: .56rem;
	}

	.third.fc {
		color: #00CCB2;
	}

	.f5 {
		font-size: .43rem;
	}
</style>
