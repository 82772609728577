<!--
 * @Descripttion: 固定在底部组件
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-04 15:44:06
-->
<template>
    <div>
        <div style="height:1.3rem"></div>
        <div class="flex-between">
            <router-link tag="div" :to="{name : 'home'}" :class="{active : activeIndex == '0'}">
                <i
                    :class="{'icon-home-active' : activeIndex == '0','icon-home' : activeIndex != '0'}">
                </i>
                <p>专家库</p>
            </router-link>
            <router-link tag="div" :to="{name: 'expertAdd'}">
                <i class="icon-add"></i>
                <p>添加专家</p>
            </router-link>
            <router-link tag="div" :to="{name : 'extraction'}">
                <i class="icon-tong"></i>
                <p>专家抽取</p>
            </router-link>
            <router-link tag="div" :to="{name: 'record'}">
                <i class="icon-biji"></i>
                <p>项目记录</p>
            </router-link>

            <router-link tag="div" :to="{name : 'mine'}" :class="{active : activeIndex == '2'}">
                <i
                    :class="{'icon-user-active' : activeIndex == '2','icon-user' : activeIndex != '2'}">
                </i>
                <p>用户中心</p>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        activeIndex: {
            required: true,
            type:[String,Number],
        },
    },
}
</script>

<style scoped>
i{width: 0.7rem; height: 0.7rem;}
p{font-size: 0.35rem}
.icon-home{background-image: url(/static/img/icon/icon-home.png);}
.icon-home-active{background-image: url(/static/img/icon/icon-home-active.png);}
.icon-expert{background-image: url(/static/img/icon/icon-expert.png);}
.icon-user{background-image: url(/static/img/icon/icon-user.png);}
.icon-user-active{background-image: url(/static/img/icon/icon-user-active.png);}
.icon-add{background-image: url(/static/img/icon/icon-add.png);}
.icon-tong{background-image: url(/static/img/icon/icon-tong.png);}
.icon-biji{background-image: url(/static/img/icon/icon-biji.png);}
.flex-between{position: fixed; bottom: 0;width: 100%; max-width: 700px; z-index: 2;}
.flex-between>div{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33%;
    background-color: white;
    position: relative;
    /* height: 1.2rem; */
}
.flex-between p{line-height: 0.6rem;}

.flex-between>div>img{margin-top: -0.6rem;}
div.active{ background-color: #E1FFFB;color: #00EECC;}
</style>
